import React from "react";


// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { profile } from "./assets";
import {
  Grid,Typography
} from "@mui/material";
import {footer, header, logo} from "../../forgot-password/src/assets"
import { deviceHeight } from "../../../framework/src/Utilities";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {  referrals, settings } from "../../landingpage/src/assets";
// Customizable Area End

import Rolesandpermissions2Controller, {
  Props,
  configJSON,
} from "./Rolesandpermissions2Controller";


export default class Rolesandpermissions2 extends Rolesandpermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
      <Grid container sx={{ width: '100%', backgroundColor: "#F5C502", padding: 1 }}>
        <Grid item xs={11}>
          <img
            style={styles.headerLogo}
            src={header.default}
          />
        </Grid>
        <Grid item sx={{ alignItems: "flex-end" }} xs={1}>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <View style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <Grid container direction="column"  >
            <Grid item xs={4} sx={{ backgroundColor: "white", display: 'flex', width: "100%" }} justifyContent={'center'} alignItems={'center'}  >
              <img
                src={logo.default}
                alt="SVG Icon"
                style={{
                  width: "375px",
                  height: "240px",
                  // top: 48px;
                  // gap: 0px;
                  // opacity: 0px;

                  alignSelf: "center"
                }}
              />
            </Grid>
            <Grid item xs={8} style={{ padding: 5 }}>

              <View style={{
                display: "flex",
                paddingTop: "20px", padding: 10
              }}>
               
                <View style={{
                  flex: 1,
                  padding: "12px"
                }}>
                  <View style={{flexDirection:"row",flex:1,marginBottom:12}}>
                  <KeyboardArrowLeftIcon style={{marginLeft:-3}}/>
                  <Typography fontWeight={400} sx={{fontSize:"14px",color:"#1D4ED8",marginTop:0.5}}>Go Back</Typography>
                  </View>
                 
          
          <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Maintenance</Typography>
          <Typography fontWeight={700} sx={{fontSize:"18px",lineHeight:"26px",marginTop:1,marginBottom:1}}>Acacia Network</Typography>
         <Typography fontWeight={400} sx={{fontSize:"14px",lineHeight:"22px"}}>Add/Edit your users and organization</Typography>
       

                </View>
                <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
            <img src={profile.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px",  font:"Inter",}}>Profile Manager</Typography></Grid>
              <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",}}>You can add/edit your Outreach Workers</Typography></Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>

         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
            <img src={referrals.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
            <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px", font:"Inter",}}>Edit Organization</Typography></Grid>
              <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px",font:"Inter",}}>You can edit and manage your Organization</Typography></Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
               
              </View>
            </Grid>
          </Grid>
        </View>
      </Grid>
      <Grid sx={{
        height: { md: deviceHeight, l: deviceHeight }, backgroundColor: '#333',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        justifyContent: "center",
        alignItems: "center",
        textAlignVertical: "center",
        alignContent: "center"
      }} item xs={12} md={4}>
        <footer >
          <div style={{ margin: '0 auto' }}>
            <img
              style={{
                width: "189.02px",
                height: "25.47px",
                backgroundColor: "#F5C502",
                borderRadius: 13,
                marginRight: 10, margin: 5
              }}
              src={footer.default}
            />
            <div style={{ margin: 3 }}>
              <Typography sx={[styles.footerText,{fontSize: "14px"}]}>Who We Are</Typography>
              <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 20 }} />
            </div>
            <div style={{ margin: 1 }}>
              <Typography sx={[styles.footerText,{fontSize: "14px"}]}>Help Center</Typography>
              <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
            </div>
            <div style={{ margin: 10 }}>
              <Typography sx={[styles.footerText,{fontSize: "14px"}]}>Why We Do It</Typography>
              <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
            </div>
            <div style={{ margin: 10 }}>
              <Typography sx={[styles.footerText,{fontSize: "14px"}]}>What We Do It</Typography>
              <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
            </div>
            <div style={{ margin: 10 }}>
              <Typography sx={[styles.footerText,{fontSize: "14px"}]} >Contact Us</Typography>
              <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
            </div>
            <div >
              <Typography fontWeight={400} sx={{ textAlign: "center",  fontSize: "16px", margin: 1 }}>Copyright © All right reserved.</Typography>
              <Typography fontWeight={400} sx={{  fontSize: "14px", margin: 1 }}>
                <a style={{ color: "#60A5FA" }}  >Privacy Policy -</a>
                <a style={{ color: "#60A5FA" }}  >Terms of Use</a>
              </Typography>
            </div>
          </div>
        </footer>
      </Grid>
    </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footerText: {
    textAlign: "center",
    fontWeight: "700",
    margin: 1
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
 
});


// Customizable Area End
