import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";
import {
  FormControl,
  Grid,
  Input, FormHelperText,
  Button, InputAdornment, IconButton,
  Typography, Box,
} from "@mui/material";
import { deviceHeight } from "../../../framework/src/Utilities";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { footer, header, logo } from "./assets";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
        <Grid container sx={{ width: '100%', backgroundColor: "#F5C502", padding: 1 }}>
          <Grid item xs={11}>
            <img
              style={styles.headerLogo}
              src={header.default}
            />
          </Grid>
          <Grid item sx={{ alignItems: "flex-end" }} xs={1}>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <View style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <Grid container direction="column"  >
              <Grid item xs={4} sx={{ backgroundColor: "white", display: 'flex', width: "100%" }} justifyContent={'center'} alignItems={'center'}  >
                <img
                  src={logo.default}
                  alt="SVG Icon"
                  style={{
                    width: "375px",
                    height: "240px",
                    // top: 48px;
                    // gap: 0px;
                    // opacity: 0px;

                    alignSelf: "center"
                  }}
                />
              </Grid>
              <Grid item xs={8} style={{ padding: 5 }}>

                <View style={{
                  display: "flex",
                  paddingTop: "20px", padding: 10
                }}>
                  <Typography sx={{
                    flex: 1,
                    padding: "20px", fontWeight: "700"
                  }}>
                    Login
                  </Typography>
                  <View style={{
                    flex: 1,
                    padding: "20px"
                  }}>

                    {this.state.loginFailMsg.length > 0 &&
                      <Box sx={{
                        backgroundColor: '#ffe5e5',
                        color: '#d32f2f',
                        padding: '10px 16px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        borderLeft: '4px solid #d32f2f',
                        marginBottom: 3
                      }}>
                        <Typography variant="body2" color="error" sx={{ fontWeight: "700" }}>
                          {this.state.loginFailMsg}
                        </Typography>
                      </Box>}
                    <Typography component="h1" style={styles.textFont}>
                      Email
                    </Typography>
                    <FormControl error={this.state.error} fullWidth required>
                      <Input
                        type="email"
                        disableUnderline
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid ', borderColor: this.state.error ? "red" : "#ccc"
                        }}
                        data-test-id="txtInputEmail"
                        placeholder={"Email"}
                        value={this.state.email}
                        onChange={(e) => this.setEmail(e.target.value)}
                        onBlur={() => this.handleBlur()}
                      />
                      <FormHelperText sx={{ marginLeft: -0.1 }}>{this.state.error ? 'Please enter an email address.' : ''}</FormHelperText>
                    </FormControl>

                  </View>
                  <View style={{
                    flex: 1,
                    padding: "20px"
                  }}>
                    <Typography component="h1" style={styles.textFont}>
                      Password
                    </Typography>
                    <FormControl error={this.state.errorPass} fullWidth>
                      <Input
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid', borderColor: this.state.errorPass ? "red" : "#ccc"
                        }}
                        disableUnderline
                        data-test-id="txtInputPassword"
                        type={this.state.enablePasswordField ? 'password' : 'text'}
                        placeholder={"Password"}
                        onBlur={() => this.handlePassblur()}
                        value={this.state.password}
                        onChange={this.setPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-test-id="togglePasswordButton"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? <VisibilityOffOutlinedIcon style={{ fill: "grey" }} /> : <VisibilityOutlinedIcon style={{ fill: "grey" }} />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText sx={{ marginLeft: -0.2 }}>{this.state.errorPass ? 'Please enter a password.' : ''}</FormHelperText>
                    </FormControl>
                  </View>
                  <View style={{ display: "flex", alignItems: "flex-start" }}>
                    <Button
                      data-test-id={"btnForgotPassword"}
                      sx={{
                        flex: 1, color: "#1D4ED8", marginBottom: 2,
                        marginLeft: 2,
                        textTransform: 'none'
                      }}
                      onClick={() => this.goToForgotPassword()}
                    >Forgot your password</Button>

                  </View>
                  <View
                    style={{
                      flex: 1,
                      padding: "20px"
                    }}
                  >
                    <Button sx={[{
                      textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#FFC300',
                      },
                    }]}
                      data-test-id={"btnEmailLogIn"}
                      onClick={() => this.doEmailLogIn()}
                    >Login</Button>
                  </View>
                  <View style={{
                    flex: 1, justifyContent: "center", alignItems: "center", margin: "30px"
                  }}>
                    <Typography component="h1" style={{ fontSize: "14px" }} >
                      <span>Do you have question? </span>
                      <span style={{ color: "#1D4ED8" }}> Contact Us</span>
                    </Typography>
                  </View>
                </View>
              </Grid>
            </Grid>
          </View>
        </Grid>
        <Grid sx={{
          height: { md: deviceHeight, l: deviceHeight }, backgroundColor: '#333',
          color: 'white',
          padding: '20px',
          textAlign: 'center',
          justifyContent: "center",
          alignItems: "center",
          textAlignVertical: "center",
          alignContent: "center"
        }} item xs={12} md={4}>
          <footer >
            <div style={{ margin: '0 auto' }}>
              <img
                style={{
                  width: "189.02px",
                  height: "25.47px",
                  backgroundColor: "#F5C502",
                  borderRadius: 13,
                  marginRight: 10, margin: 5
                }}
                src={footer.default}
              />
              <div style={{ margin: 10 }}>
                <Typography sx={{fontSize: "14px"}} style={styles.footerText}>Who We Are</Typography>
                <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 20 }} />
              </div>
              <div style={{ margin: 10 }}>
                <Typography sx={{fontSize: "14px"}} style={styles.footerText}>Help Center</Typography>
                <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
              </div>
              <div style={{ margin: 10 }}>
                <Typography sx={{fontSize: "14px"}} style={styles.footerText}>Why We Do It</Typography>
                <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
              </div>
              <div style={{ margin: 10 }}>
                <Typography sx={{fontSize: "14px"}} style={styles.footerText}>What We Do It</Typography>
                <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
              </div>
              <div style={{ margin: 10 }}>
                <Typography sx={{fontSize: "14px"}} style={styles.footerText}>Contact Us</Typography>
                <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
              </div>
              <div >
                <Typography style={{ textAlign: "center", fontWeight: "400", fontSize: "16px", margin: 5 }}>Copyright © All right reserved.</Typography>
                <Typography style={{ fontWeight: "400", fontSize: "14px", margin: 5 }}>
                  <a style={{ color: "#60A5FA" }}  >Privacy Policy -</a>
                  <a style={{ color: "#60A5FA" }}  >Terms of Use</a>
                </Typography>
              </div>
            </div>
          </footer>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footerText: {
    textAlign: "center",
    fontWeight: "700",
    margin: 5
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  }
});
// Customizable Area End
