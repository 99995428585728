import React from "react";

// Customizable Area Start
import {
  View
} from "react-native";
import {
  Grid,
  Button,
  Typography,
  Box,Popover,IconButton
} from "@mui/material";
import {footer, header, logo} from "../../forgot-password/src/assets"
import { deviceHeight } from "../../../framework/src/Utilities";
import PersonIcon from '@mui/icons-material/Person';
import { arrow, datat, maintenance, referrals, settings } from "./assets";
// Customizable Area End

import LandingPageController, {
    Props,
    configJSON
  } from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box style={webStyle.landingPageView}>
           <View style={{backgroundColor:"#f3f3f1"}} >
        <Grid container sx={{ width: '100%',backgroundColor: "#F5C502",padding:1,justifyContent:"center"}}>
    <Grid item xs={11}>
    <img
            style={{
              height: 20,
              backgroundColor: "#F5C502",
              // width: "100%",
              borderRadius:"24px",
              marginRight: 10,
              marginTop:8
            }}
            src={header.default}
          />
    </Grid>
    <Grid item sx={{alignItems:"flex-end"}} xs={1}>
    <IconButton data-test-id={"btnhandle"} onClick={this.handleClick}> <PersonIcon /></IconButton> 
    </Grid>
    <Popover
          id={id}
          data-test-id={"btnpopover"}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Button data-test-id={"btngotoEmailLogIn"} sx={{textTransform:"none"}} onClick={()=>this.goToPage("EmailAccountLoginBlock")}>
          <Typography>{configJSON.btnExampleTitle}</Typography>
          </Button>
         
        </Popover>
  </Grid>
  <Grid item xs={12} >
        <View >
          <Grid container direction="column"  >
        <Grid item xs={4} sx={{backgroundColor:"white", display: 'flex',width:"100%" }} justifyContent={'center'} alignItems={'center'}  >
      <img
        src={logo.default}
        alt="SVG Icon"
        style={{
          width: 150,  
          height: 200,alignSelf:"center"
        }}
      />
        </Grid>
        
        {this.state.role === "Outreach Worker" ?
        <Grid item xs={8} >
       
        <Grid direction={"column"} style={webStyle.formWrapper}>
        
          <View style={webStyle.inputWrapper}>
          <Typography component="h6" sx={{fontSize:"24px",marginBottom:"15px",marginRight:3,fontWeight:"700"}} >
          Outreach Worker Console
        </Typography>
        <Typography sx={{marginBottom:"20px",fontSize:"14px", fontWeight:"700"}} >
        Acacia Network / Full Name
        </Typography>
  <Typography component="h1" style={webStyle.textFont}>
  You will be able to access the services that can be provided to the community.
        </Typography>
     
          
          </View>
       
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button  sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnSafety"}
            >Community Safety and Cleanliness</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnHarm"}
            >Harm Reduction Supplies & Education</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnHealthcare"}
            >Healthcare Access Facilitation</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnAdministered"}
            >Administered Services</Button>
          </View>
          <View
            style={webStyle.buttonsWrapper}
          >
            <Button sx={[webStyle.buttonsWrapper,webStyle.workerButton]} data-test-id={"btnBookings"}
            >My Bookings</Button>
          </View>
          
        </Grid> 
        </Grid>
        :
        
        <Grid item xs={8} >

<Grid direction={"column"} style={webStyle.formWrapper}>
<View style={webStyle.inputWrapper}>
          <Typography style={webStyle.header}>Organization Console</Typography>
          <Typography style={webStyle.header2}>Acacia Network</Typography>
         <Typography style={webStyle.header3}>Add/Edit your outreach workers and take a look into data extracts.</Typography>
         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
            <img src={maintenance.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
            <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography style={webStyle.textBold}>Maintenance</Typography></Grid>
              <Grid item xs={12}><Typography style={webStyle.textSmall}>You'll be able to add/edit the organization and users.</Typography></Grid>
            </Grid>
          </Grid>
          <Grid onClick={()=>this.goToPage("Rolesandpermissions2")}  item xs={2}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
          <img src={referrals.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography style={webStyle.textBold}>Referrals</Typography></Grid>
              <Grid item xs={12}><Typography style={webStyle.textSmall}>You'll be able to manage the organization's bookings.</Typography></Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
          <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
          <img src={datat.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography style={webStyle.textBold}>Data Extracts</Typography></Grid>
              <Grid item xs={12}><Typography style={webStyle.textSmall}>You'll be able to see and analyze work performance.</Typography></Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
          <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={arrow.default} alt="mainImg" /></div>
          </Grid>
         </Grid>
         </View>
        </Grid> 
        </Grid>}
      
          </Grid>
        </View>
        </Grid>
  <Grid sx={[webStyle.footer,{height:{md:deviceHeight,l:deviceHeight}}]} item xs={12} md={4}>
  <footer >
        <div style={{margin: '0 auto'}}>
        <img
            style={{width: "189.02px",
              height: "25.47px",
              backgroundColor: "#F5C502",
              borderRadius:30,
              marginRight: 10,margin:5
            }}
            src={footer.default}
          />
          <div style={{margin:10}}>
          <Typography sx={webStyle.footerText}>Who We Are</Typography>
          <View style={{height:1,backgroundColor:"#F5C502",paddingHorizontal:20}} />
          </div>
          <div style={{margin:10}}>
          <Typography sx={webStyle.footerText}>Help Center</Typography>
          <View style={{height:1,backgroundColor:"#F5C502",paddingHorizontal:10}} />
          </div>
          <div style={{margin:10}}>
          <Typography sx={webStyle.footerText}>Why We Do It</Typography>
          <View style={{height:1,backgroundColor:"#F5C502",paddingHorizontal:10}} />
          </div>
          <div style={{margin:10}}>
          <Typography sx={webStyle.footerText}>What We Do It</Typography>
          <View style={{height:1,backgroundColor:"#F5C502",paddingHorizontal:10}} />
          </div>
          <div style={{margin:10}}>
          <Typography sx={webStyle.footerText}>Contact Us</Typography>
          <View style={{height:1,backgroundColor:"#F5C502",paddingHorizontal:10}} />
          </div>
          <div >
          <Typography style={{textAlign:"center",fontWeight:"400",fontSize:"16px",margin:5}}>Copyright © All right reserved.</Typography>
          <Typography style={{fontWeight:"400",fontSize:"14px",margin:5}}>
              <a style={{color: "#60A5FA"}}  >Privacy Policy -</a>  
              <a style={{color: "#60A5FA"}}  >Terms of Use</a>
              </Typography>
          </div>
        </div>
      </footer>
        </Grid>
        </View>
    </Box>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  landingPageView: {
    // flex: 1,
    // justifyContent: "center",
    // alignItems: "center"
  },
  landingPageText: {
    fontSize: 42,
    letterSpacing: 2,
    fontWeight: "bold",
    color: "#323441",
    marginTop: 15
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center' ,
    justifyContent:"center",
    alignItems:"center",
    textAlignVertical:"center",
    alignContent:"center",
    marginTop:5
    
  },
  footerContent: {
    margin: '0 auto',
  },
  textFont: {
    fontFamily: "Helvetica",
    padding:5,
  },
  formWrapper: {
    display: "flex",
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  buttonsWrapper: {
    flex: 1,
    textTransform: 'none',
  },
  btnStyle:{
    margin:"30px",marginLeft:15,marginRight:15,background: "#F5C502",color:"black"  ,'&:hover': {backgroundColor: '#FFC300',  
            color: '#black'}
  },
  textBold:{
    fontWeight: "700",
    font:"Inter",
    fontSize:"14px"
  },
  textSmall:{
    font:"Inter",
    fontWeight: "400",
    fontSize:"12px"
  },
  footerText:{
    textAlign:"center",
    fontWeight:"700",
    fontSize:"14px",
    margin:1
  },
  header:{font:"Inter",fontSize:"24px",lineHeight:"32px",fontWeight:"700"},
  header2:{font:"Inter",fontSize:"18px",lineHeight:"26px",fontWeight:"700",margin:2},
  header3:{font:"Inter",fontSize:"14px",lineHeight:"22px",fontWeight:"400",margin:3},
  workerButton:{margin:"10px",marginLeft:3,marginRight:3,fontSize:"16px",fontWeight:"700",background: "#F5C502",color:"black"  ,
    '&:hover': {backgroundColor: '#FFC300',  
    color: '#black',  }}
}
  // Customizable Area End
