import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
} from "react-native";
import {
  FormControl,
  Grid,
  Input,
  FormHelperText,
  Button,
  Typography
} from "@mui/material";
import { deviceHeight } from "../../../framework/src/Utilities";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { footer, header, logo } from "./assets";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;

    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            this.isPlatformWeb() ? styles.containerWeb : styles.containerMobile
          }
        >
          <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
            {/* Customizable Area Start */}
            <Grid container sx={{flexDirection:{md:"row-reverse"},backgroundColor:"#f3f3f1"}}>
            <Grid container sx={{ width: '100%',backgroundColor: "#F5C502",padding:1}}>
    <Grid item xs={11}>
    <img
            style={{
              height: 20,
              backgroundColor: "#F5C502",
              // width: "100%",
              borderRadius:"24px",
              marginRight: 10
            }}
            src={header.default}
          />
    </Grid>
    <Grid item sx={{alignItems:"flex-end"}} xs={1}>
    </Grid>
  </Grid>
        <Grid item xs={12} md={8}>
        <View style={styles.body}>
          <Grid container direction="column"  >
        <Grid item xs={4} sx={{backgroundColor:"white", display: 'flex',width:"100%" }} justifyContent={'center'} alignItems={'center'}  >
      <img
        src={logo.default}
        alt="SVG Icon"
        style={{
          width: 150,  
          height: 200,alignSelf:"center"
        }}
      />
        </Grid>
        <Grid item xs={8} >
       
          <View style={styles.formWrapper}>
          
            <View style={styles.inputWrapper}>
            <Typography component="h6" sx={{fontSize:"24px",marginBottom:"15px",marginRight:3,fontWeight:"700"}} >
            Forgot password
          </Typography>
          <Typography sx={{marginBottom:"20px",fontSize:"14px"}} style={styles.paraText}>
          We’ll send you a link to reset your password. If you don’t receive an email, please check your spam folder.
          </Typography>
    <Typography component="h1" sx={{ fontWeight:"700"}} style={styles.textFont}>
            Email Address
          </Typography>
          <FormControl error={this.state.error} fullWidth required>
        <Input
          type="email"
          disableUnderline
          sx={{backgroundColor:"white",borderWidth:1,borderRadius:"8px", padding: '10px',
            border: '1px solid ',borderColor:this.state.error?"red":"black"}}
          data-test-id="txtInputEmail"
          placeholder={"Email"}
          value={this.state.emailValue}
          onChange={(e) => this.setState({emailValue:e.target.value,error:false})}
          onBlur={this.handleBlur}
        />
        <FormHelperText>{this.state.error ? 'Please enter an email address' : ''}</FormHelperText>
      </FormControl>
            
            </View>
            <View style={styles.inputWrapper}>
              <Button onClick={()=>this.goToLogin}
                data-test-id={"btnForgotPassword"}
                sx={{textAlign:"left",justifyContent:"flex-start",textTransform:"none",color: "#60A5FA"}}
              >Back to login</Button>
             
            </View>
            <View
              style={styles.buttonsWrapper}
            >
              <Button sx={[styles.buttonsWrapper,{margin:"20px",background: "#F5C502",color:"black"  ,'&:hover': {
            backgroundColor: '#FFC300',  
            color: '#black',             
          },}]}
                data-test-id={"btnEmailLogIn"}
              >Send Recovery Link</Button>
            </View>
            <View style={styles.inputWrapper}>
            <Typography sx={{justifySelf:"center",alignItems:"center"}} component="h1"  style={styles.textFont}>
              <span>If you didn't receive a link, </span>
             <span style={{color: "#60A5FA"}}> Resend</span>
          </Typography>
          
              </View>
          </View> 
          </Grid>
          </Grid>
        </View>
        </Grid>
        <Grid style={styles.footer} sx={{height:{md:deviceHeight,l:deviceHeight}}} item xs={12} md={4}>
        <footer >
          <div style={styles.footerContent}>
            <img src={footer.default} style={{height:"25px",borderRadius:"24px"}}/>
            <div style={{margin:10}}>
            <Typography style={{textAlign:"center"}}>Who We Are</Typography>
            <View style={{height:1,backgroundColor:"yellow",paddingHorizontal:20}} />
            </div>
            <div style={{margin:10}}>
            <Typography style={{textAlign:"center"}}>What We Do</Typography>
            <View style={{height:1,backgroundColor:"yellow",paddingHorizontal:10}} />
            </div>
            <div style={{margin:10}}>
            <Typography style={{textAlign:"center"}}>What We Do It</Typography>
            <View style={{height:1,backgroundColor:"yellow",paddingHorizontal:10}} />
            </div>
            <div style={{margin:10}}>
            <Typography style={{textAlign:"center"}}>Contact Us</Typography>
            <View style={{height:1,backgroundColor:"yellow",paddingHorizontal:10}} />
            </div>
            <div >
              <p>Copyright © All right reserved.</p>
              <p>
              
                <a href="#" style={{color: "#60A5FA"}}>Privacy Policy</a> - 
                <a href="#" style={{color: "#60A5FA"}}>Terms of Use</a>
              </p>
            </div>
          </div>
        </footer>
        </Grid>
      </Grid>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
   
  },
  containerWeb: {
    
  },
  main: {
    display: "flex",
    alignItems: "center",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textFont: {
    fontFamily: "Helvetica",
    padding:5,
  },
  formWrapper: {
    display: "flex",
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    textTransform: 'none',
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center' ,
    justifyContent:"center",
    alignItems:"center",
    textAlignVertical:"center",
    alignContent:"center",
    
    
  },
  footerContent: {
    margin: '0 auto',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    marginBottom: '15px',
  },
  navLink: {
    color: 'white',
    margin: '5px 0',
  },
  navLinkHover: {
  },
  
  paraText:{
    fontFamily:"text-sm/font-normal",
  }
});
